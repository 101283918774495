import React from 'react';
import Router from 'next/router';
import Script from 'next/script';

declare global {
  interface Window {
    analytics: any;
  }
}

interface PageProps {
  children: React.ReactNode;
}

// Track client-side page views with Segment
Router.events.on('routeChangeComplete', () => {
  try {
    window?.analytics?.page('public ', window?.location?.pathname);
  } catch (err) {
    // browser blocking segment
  }
});

export const Page = ({ children }: PageProps) => (
  <>
    {children}
    <Script
      id="segment-script"
      src={
        process.env.NEXT_PUBLIC_ENV === 'production'
          ? '/next-assets/scripts/segment-prod.js'
          : '/next-assets/scripts/segment-dev.js'
      }
    />
    <Script id="intercom-reboot-script" src="/next-assets/scripts/intercom-reboot.js" />
    <Script
      strategy="lazyOnload"
      id="trust-pilot"
      crossOrigin="anonymous"
      integrity="sha384-jYOWKBkA3X7T6yOIzozyktEvSt/ld/G0bSz8OEFWx+ofNMAsnWE6cWFoIwXkY16b"
      src={`${process.env.NEXT_PUBLIC_STATIC_URL}/marketing/trustpilot/2024-02-05-widget.js`}
    />
  </>
);
