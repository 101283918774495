import { Inter } from 'next/font/google';
import { AppPropsWithLayout } from '../types/layoutExtensions';
import '../styles/index.scss';
import { Page } from '../components/Page';

const inter = Inter({ subsets: ['latin'] });

// If loading a variable font, you don't need to specify the font weight

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page: any) => page);
  return getLayout(
    <Page>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style jsx global>
        {`
        html {
          font-family: ${inter.style.fontFamily};
        }
      `}
      </style>
      <Component {...pageProps} />
    </Page>,
  );
}

export default MyApp;
